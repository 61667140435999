<template>
	<div class="gg-container">
		<div class="search-container">
			<!--输入框-->
		<!--	<div class="search-container-fn-input">
				<label>search01：</label>
				<el-input
					style="width:200px"
					size="small"
					placeholder="search01"
					prefix-icon="el-icon-search"
					v-model="searchParams.search"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>
			<div class="search-container-fn-input">
				<label>select01：</label>
				<el-select
					style="width:200px"
					v-model="searchParams.select"
					placeholder="select01"
					size="small"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in options"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div>-->
			<div class="search-container-fn-input">
				<label>通话日期：</label>
				<el-date-picker
					v-model="choiceDate"
					type="daterange"
					size="mini"
					value-format="yyyy-MM-dd HH-mm-ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDate">
				</el-date-picker>
			</div>
			
			<!--按钮样式-->
			<!--<div style="margin-left: auto; display: flex; justify-content: space-around">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlebtn01('新增')">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">新增</span>
					</el-button>
				</div>
				
				&lt;!&ndash;对应第三方图标的button样式&ndash;&gt;
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handlebtn02(scope.$index, scope.row)">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">上传</span>
					</el-button>
				</div>
			</div>-->
		
		</div>
		
		<!----------------表格---------------->
		<el-table
			border
			v-loading="listLoading"
			height="625"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			style="width: 100%;z-index:0">
			<el-table-column
				prop="exten"
				label="分机号"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="customer"
				label="分机人员"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="star"
				label="星级"
				align="center"
				width="100">
			</el-table-column>
			<!--<el-table-column-->
			<!--	prop="thread_count"-->
			<!--	label="当日分线"-->
			<!--	align="center"-->
			<!--	width="100">-->
			<!--</el-table-column>-->
			<el-table-column
				prop="call_out_count"
				label="呼出次数"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="call_out_duration_format"
				label="呼出时长"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="valid_count"
				label="有效次数"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="billsec_format"
				label="有效时长"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="call_in_count"
				label="呼入次数"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="call_in_duration_format"
				label="呼入时长"
				align="center"
				width="100">
			</el-table-column>
			<el-table-column
				prop="count"
				label="合计次数"
				align="center"
				width="100">
			</el-table-column>
			
	<!--		<el-table-column
				prop="call_out_duration"
				label="呼出时秒"
				align="center">
			</el-table-column>-->
<!--			<el-table-column
				prop="billsec"
				label="有效时秒"
				align="center">
			</el-table-column>-->
<!--			<el-table-column
				prop="call_in_duration"
				label="呼入时秒"
				align="center">
			</el-table-column>-->
			
			<!--<el-table-column align="center" width="200" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" icon="el-icon-edit"
										 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleTable01(scope.$index, scope.row)">编辑
					</el-button>
					<el-button size="mini" type="danger" icon="el-icon-delete"
										 style="padding: 7px 8px;margin-left: 5px;"
										 @click="handleTable02(scope.$index, scope.row)">删除
					</el-button>
				</template>
			</el-table-column>-->
		</el-table>
		
		<!----------------分页---------------->
		<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>
	
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {getPhoneStatus} from '@/api/ggkq';

export default {
  name: "phoneStatus",
  components: {
    Pagination,
  },
  created () {
    this.getList()
  },
  data () {
    return {
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      tableData: [],
    }
  },
  methods: {
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      getPhoneStatus(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleChangeDate() {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.created_at = ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0,10)
        _tmp1 = this.choiceDate[1].substring(0,10)
        this.searchParams.created_at += _tmp0 + ","
        this.searchParams.created_at += _tmp1
      } else {

        this.searchParams.created_at = ""
      }
      this.getList('restPage')
    }
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
